

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.card-title {
  margin-top: 0;
}

.App {
  background: linear-gradient(180deg, rgba(47,41,65,1) 0%, rgba(0,0,0,1) 94%, rgba(0,0,0,1) 100%);
  min-height: 100vh;  
  text-align: center;  
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  
}

.ParametersCard {
  background: #141416;
  font-size: 15px;
  text-align: left;
  margin-top: 20px;
}

a:link {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

td {
  border: none;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}